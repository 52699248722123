import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LibraryAddCheckSharpIcon from "@mui/icons-material/LibraryAddCheckSharp";
import { fetchAuthData } from "../../services/apiConfig";

const UserInitialQuestions = ({
  handleSendMessage,
  setMessages,
  setNewMessage,
  messages,
  email,
}) => {
  const [selected, setSelected] = useState({
    email: email,
    age_group: null,
    account_type: null,
    risk_style: null,
    has_financial_advisor: null,
    investor_type: null,
  });
  const [isnewUser, setIsNewUser] = useState(true);
  const [isUserEditing, setIsUserEditing] = useState(false);
  const [isNewUserEditing, setIsNewUserEditing] = useState(false);

  const handleSelect = (category, value) => {
    if (!isnewUser) {
      setIsUserEditing(true); // Set isUserEditing to true when a selection is made
      setSelected((prev) => ({ ...prev, [category]: value }));
    } else {
      setIsNewUserEditing(true);
      setSelected((prev) => ({ ...prev, [category]: value }));
    }
  };

  const categories = [
    {
      title: "Age Group",
      name: "age_group",
      options: ["GenX", "Millennial", "Baby Boomer", "Other"],
    },
    {
      title: "Primary Investment Account Type",
      name: "account_type",
      options: ["401k", "IRA", "Brokerage account"],
    },
    {
      title: "Risk Style",
      name: "risk_style",
      options: ["Low", "Moderate", "Aggressive"],
    },
    {
      title: "Has Financial Advisor",
      name: "has_financial_advisor",
      options: ["Yes", "No"],
    },
    {
      title: "Investor Type",
      name: "investor_type",
      options: ["Short-Term", "Long-Term"],
    },
  ];

  useEffect(() => {
    // Fetch user profile data when the component mounts
    const fetchUserProfile = async () => {
      try {
        const response = await fetchAuthData("getUserProfile", "GET", null, {
          email: localStorage.getItem("email"),
        });
        if (response !== null) {
          setIsNewUser(false);
          setSelected((prev) => ({
            ...prev,
            ...response, // Update selected state with the fetched response
          }));
        } else {
          console.log("Its a new user");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    setIsNewUserEditing(false);
    const allSelected = Object.values(selected).every(
      (value) => value !== null
    );
    if (allSelected && isnewUser) {
      const handleApiAndSendMessage = async () => {
        try {
          messages[messages.length - 1] = {
            text: "Thank you for providing the information. We will use this to provide you with the best possible recommendations.",
            isUserProfileCapture: false,
          };

          const result = await fetchAuthData(
            "submitUserProfile",
            "POST",
            selected
          );
          console.log("fetchAuthData result:", result);

          handleSendMessage(messages[messages.length - 2].text);
        } catch (error) {
          console.error("Error in fetchAuthData or handleSendMessage:", error);
        }
      };

      handleApiAndSendMessage();
    }
  }, [selected]);

  // Handle the click of the LibraryAddCheckSharpIcon to save user data and update editing state
  const handleSaveClick = () => {
    setIsUserEditing(false); // Stop the editing mode
    const allSelected = Object.values(selected).every(
      (value) => value !== null
    );
    if (allSelected) {
      const handleApiAndSendMessage = async () => {
        try {
          const result = await fetchAuthData(
            "submitUserProfile",
            "POST",
            selected
          );
          // Sending final message after saving
          console.log(messages);
          messages[messages.length - 1] = {
            text: "Your information has been saved. Thank you!",
            isUserProfileCapture: false,
          };
          setMessages(messages);
          setNewMessage("");
        } catch (error) {
          console.error("Error saving user profile:", error);
        }
      };

      handleApiAndSendMessage();
    } else {
      // Handle case where not all selections are made yet
      console.log("Please complete all selections before saving.");
    }
  };

  return (
    <Grid
      container
      spacing={3}
      style={{
        marginTop: "0px",
        marginLeft: "0px",
        backgroundColor: isUserEditing
          ? "rgb(237, 247, 237)"
          : "rgb(237, 247, 237)",
        borderRadius: "15px",
        paddingLeft: isUserEditing ? "20px" : "0px",
        position: "relative",
        transition:
          "padding-left 0.3s ease-in-out, background-color 0.3s ease-in-out",
      }}
    >
      {categories.map((category) => (
        <Grid
          item
          xs={12}
          key={category.name}
          style={{ display: "flex", padding: "1vw" }}
        >
          <Typography variant="subtitle1" style={{ width: "40vw" }}>
            {category.title}
          </Typography>
          <Grid container spacing={2}>
            {category.options.map((option) => (
              <Grid item key={option}>
                <Button
                  variant="contained"
                  onClick={() => handleSelect(category.name, option)}
                  sx={{
                    backgroundColor:
                      selected[category.name] === option ? "#369902" : "#FFF",
                    color:
                      selected[category.name] === option ? "white" : "black",
                    position: "relative",
                    textTransform: "none",
                    boxShadow: "none",
                    border: "1px solid #f5f5f5",
                    "&:hover": {
                      backgroundColor: "#369902",
                      color: "white",
                      boxShadow: "none",
                    },
                  }}
                >
                  {option}
                  {selected[category.name] === option && (
                    <CheckCircleIcon
                      sx={{
                        position: "absolute",
                        top: -5,
                        right: -5,
                        color: "white",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
      {isUserEditing && (
        <>
          <Tooltip title="Save" arrow>
            <LibraryAddCheckSharpIcon
              sx={{
                position: "absolute",
                top: -10,
                right: 0,
                color: "#124734",
                fontSize: "2.5vw",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.01)", // Increase size on hover
                  fontSize: "2.6vw", // Optional: increase font size slightly on hover
                },
              }}
              onClick={handleSaveClick} // Trigger save logic on click
            ></LibraryAddCheckSharpIcon>
          </Tooltip>
        </>
      )}
    </Grid>
  );
};

export default UserInitialQuestions;
