import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Logic to clear localStorage when a new version is deployed
const currentVersion = process.env.REACT_APP_VERSION; // Use the version you set in your .env or package.json

// Get the stored version from localStorage
const storedVersion = localStorage.getItem("appVersion");

// Check if the app version is different (i.e., it's a new version)
if (storedVersion !== currentVersion) {
  // console.log("local storage clear");
  // Clear localStorage if versions don't match
  localStorage.clear();
  localStorage.setItem("isLoggedIn", "false");
  // Store the new version in localStorage
  localStorage.setItem("appVersion", currentVersion);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
