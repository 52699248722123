import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

export const StyledAppBar = styled(AppBar)({
  display: "flex",
  justifyContent: "space-between",
  gap: "2vw",
  alignItems: "center",
  backgroundColor: "#124734",
  color: "white",
  flexDirection: "row",
  padding: "1vh 8vw",
});

export const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  gap: "2vw",
  height: "10vh !important",
});
