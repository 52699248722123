import React from "react";
import { Alert, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomPasswordField from "../../molecules/CustomTextField/CustomPasswordField";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import {
  DialogSectionI,
  StyledDialog,
  StyledDialogContent,
  StyledDialogActions,
  StyledIconButton,
  StyledDialogTitleRegisterI,
} from "./LoginDialogStyled";
import CustomThreeDotLoader from "../../atoms/Progress/CustomThreeDotLoader";

const ResestPasswordDialog = ({
  open,
  onClose,
  handleResetPassword,
  //   username,
  //   setUsername,
  resetPassword,
  setResetPassword,
  retypeResetPassword,
  setRetypeResetPassword,
  isPasswordMatch,
  setIsPasswordMatch,
  loading,
  setLoading,
  APIError,
  setAPIError,
  isEmailValid,
  setIsEmailValid,
  setOpenRegisterDialog,
  transit,
  setTransit,
  sendVerificationEmail,
  sendPasswordVerificationEmail,
  verificationError,
  setVerificationError,
  setOpenLoginDialog,
}) => {
  const handlePasswordChange = (value) => {
    setLoading(false);
    setIsPasswordMatch(null);
    setResetPassword(value);
    setAPIError(false);
    setIsEmailValid(true);
    setVerificationError("");
  };
  const handleRetypePasswordChange = (value) => {
    setLoading(false);
    setIsPasswordMatch(null);
    setRetypeResetPassword(value);
    setAPIError(false);
    setIsEmailValid(true);
    setVerificationError("");
  };
  const handlePasswordKeyDown = (e) => {
    if (e.key === "Enter") {
      handleResetPassword(); // Call the same function as the button click
    }
  };

  const handleRegisterClick = async () => {
    setTransit(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    onClose();
    setOpenRegisterDialog(true);
    setTransit(false);
    // setPassword("");
  };

  const handleLoginClick = async () => {
    setTransit(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    onClose();
    setOpenLoginDialog(true);
    setTransit(false);
    // setNewPassword("");
    // setRetypePassword("");
    setAPIError(false);
    setIsEmailValid(true);
    // setIsPasswordMatch(null);
    setIsEmailValid(true);
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledIconButton edge="end" color="inherit" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>

      <DialogSectionI moved={transit}>
        <StyledDialogTitleRegisterI>
          <Typography variant="h6" align="center">
            Set new password
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            Your password must be at least 8 characters long.
          </Typography>
        </StyledDialogTitleRegisterI>
        <StyledDialogContent>
          <CustomPasswordField
            value={resetPassword}
            onChange={(e) => handlePasswordChange(e.target.value)}
            placeholder="Enter new password"
            onKeyDown={handlePasswordKeyDown}
          />
          <CustomPasswordField
            value={retypeResetPassword}
            onChange={(e) => handleRetypePasswordChange(e.target.value)}
            placeholder="Re-enter new password"
            onKeyDown={handlePasswordKeyDown}
          />
          {isPasswordMatch === false && (
            <Alert severity="error" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> Passwords do not match.
            </Alert>
          )}
          {/* {isEmailValid === false && (
            <Alert severity="warning" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> Please provide a genuine email address.
            </Alert>
          )} */}
          {APIError.length > 0 &&
            APIError === "Please verify your email address." && (
              <Alert severity="warning" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
                <Typography
                  variant="body2"
                  component="span"
                  onClick={sendVerificationEmail}
                  style={{
                    color: "rgb(102, 60, 0)",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "4px",
                    fontWeight: "bold",
                  }}
                >
                  Resend
                </Typography>
              </Alert>
            )}
          {APIError.length > 0 &&
            APIError !== "Please verify your email address." && (
              <Alert severity="error" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
              </Alert>
            )}
        </StyledDialogContent>
        <StyledDialogActions>
          {loading ? (
            <CustomButton color="log" variant="contained">
              <CustomThreeDotLoader />
            </CustomButton>
          ) : (
            <CustomButton
              color="log"
              variant="contained"
              onClick={handleResetPassword}
            >
              Continue
            </CustomButton>
          )}
          <Typography variant="body2" style={{ marginTop: "20px" }}>
            Already have an account?{" "}
            <Typography
              variant="body2"
              component="span"
              onClick={handleLoginClick} // Call the register click handler here
              style={{
                color: "#369902", // Change color as needed
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Log in
            </Typography>
          </Typography>
          <Typography variant="body2" style={{ marginTop: "2px" }}>
            New user?{" "}
            <Typography
              variant="body2"
              component="span"
              onClick={handleRegisterClick} // Call the register click handler here
              style={{
                color: "#369902", // Change color as needed
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Create your account
            </Typography>
          </Typography>
        </StyledDialogActions>
      </DialogSectionI>
    </StyledDialog>
  );
};

export default ResestPasswordDialog;
