import React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogSectionII,
  StyledStrong,
  StyledTypography,
  StyledDialogTitleI,
  StyledDialogActions,
  StyledIconButton,
  StyledDialogContentVerify,
  StyledDialogVerify,
} from "./LoginDialogStyled";
import CustomThreeDotLoader from "../../atoms/Progress/CustomThreeDotLoader";

const VerificationDialog = ({
  open,
  onClose,
  setOpenRegisterDialog,
  setOpenLoginDialog,
  username,
  transit,
  setTransit,
  loading,
  sendVerificationEmail,
  APIError,
  isResettingPassword,
  sendPasswordVerificationEmail,
  // verificationFailed,
}) => {
  return (
    <StyledDialogVerify open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledIconButton edge="end" color="inherit" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <DialogSectionII moved={transit}>
        <StyledDialogTitleI>
          {isResettingPassword ? "Reset your password" : "Verify your email"}
        </StyledDialogTitleI>
        <StyledDialogContentVerify>
          <Box mb={2} />
          {loading ? (
            <Alert
              severity="info"
              color="info"
              sx={{ border: "1px solid grey", backgroundColor: "#fff" }}
            >
              <AlertTitle>
                {isResettingPassword
                  ? "Reset your password"
                  : "Verification Pending"}
                <CustomThreeDotLoader />
              </AlertTitle>
              An email is being sent to <strong>{username}</strong>.
            </Alert>
          ) : (
            <Alert
              severity="info"
              color="info"
              sx={{ border: "1px solid grey", backgroundColor: "#fff" }}
            >
              <AlertTitle>
                {" "}
                {isResettingPassword
                  ? "Reset your password"
                  : "Verification Pending"}
              </AlertTitle>
              {isResettingPassword ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `An email has been sent to <strong>${username}</strong>. Please click on the re-set password link in the email.`,
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `An email has been sent to <strong>${username}</strong>. Please click on the verification link in the email.`,
                  }}
                />
              )}
            </Alert>
          )}

          {APIError.length > 0 && (
            <Alert severity="success" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> {APIError}
            </Alert>
          )}
        </StyledDialogContentVerify>
        <Box mb={2} />
        <StyledDialogActions>
          {/* {loading ? (
            <CustomButton color="log" variant="contained">
              <CustomThreeDotLoader />
            </CustomButton>
          ) : (
            <CustomButton
              color="log"
              variant="contained"
              // onClick={() => window.open("https://mail.google.com", "_blank")}
            >
              Check your email
            </CustomButton>
          )} */}

          <StyledTypography>
            Did not receive email?{" "}
            <StyledStrong
              onClick={
                isResettingPassword
                  ? sendPasswordVerificationEmail
                  : sendVerificationEmail
              }
            >
              Resend email
            </StyledStrong>
          </StyledTypography>
        </StyledDialogActions>
      </DialogSectionII>
      {/* )} */}
    </StyledDialogVerify>
  );
};

export default VerificationDialog;
