import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import TnC from "./pages/TnC/TnC";
import Disclosures from "./pages/Disclosures/Disclosures";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import AuthProvider, { AuthContext } from "./context/AuthContext";

// Define custom Material-UI theme with Montserrat
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: 'Montserrat, sans-serif';
        }
      `,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            {/* Protected routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestOnlyRoute>
                  <Login />
                </GuestOnlyRoute>
              }
            />

            {/* Public route */}
            <Route path="/termsandconditions" element={<TnC />} />
            <Route path="/disclosures" element={<Disclosures />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />

            {/* Redirect unknown paths */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

// Handles logged-in-only routes
function ProtectedRoute({ children }) {
  const { isLoggedIn } = React.useContext(AuthContext);

  if (!isLoggedIn) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" replace />;
  }

  return children;
}

// Handles routes restricted to non-logged-in users
function GuestOnlyRoute({ children }) {
  const { isLoggedIn } = React.useContext(AuthContext);

  if (isLoggedIn) {
    // Redirect to home if user is already authenticated
    return <Navigate to="/" replace />;
  }

  return children;
}

export default App;
