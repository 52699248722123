import React from "react";
import { styled } from "@mui/system";

const DotContainer = styled("span")({
  display: "flex",
  "& span": {
    display: "flex",
    marginRight: "3px",
    width: "8px",
    height: "8px",
    backgroundColor: "#fff", // Change color as needed
    borderRadius: "50%",
    animation: "dotFlashing 1s infinite ease-in-out both",
  },
  "& span:nth-of-type(2)": {
    animationDelay: "0.2s",
  },
  "& span:nth-of-type(3)": {
    animationDelay: "0.4s",
  },
  "@keyframes dotFlashing": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0.3 },
    "100%": { opacity: 1 },
  },
});

const CustomHotHandOffLoader = () => (
  <DotContainer>
    <span></span>
    <span></span>
    <span></span>
  </DotContainer>
);

export default CustomHotHandOffLoader;
