import React from "react";
import { LinearProgress } from "@mui/material";
import { styled } from "@mui/system";

// Create a styled version of LinearProgress to customize animation speed
const SlowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "18px",
  backgroundColor: "#A8E6CF",
  opacity: 0.1,
  borderRadius: "2px",
  padding: "4px",
  // Override the default linear progress animation with a slower version
  "& .MuiLinearProgress-bar": {
    transition: "transform 5s ease-out", // Adjust duration to make it slower
    backgroundColor: "#124734",
    opacity: 0.5,
  },
}));

function CustomLinearProgress(props) {
  return (
    <>
      <SlowLinearProgress style={{ width: "100%" }} />
    </>
  );
}

export default CustomLinearProgress;
