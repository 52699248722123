import * as React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import { StyledAppBar, StyledToolbar } from "./LandingHeaderStyled";
import ZackInsightsWhiteLogo from "../../../assests/ZackInsightsWhiteLogo.svg";
import LandingPage from "../LandingPage/LandingPage";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element,
};

export default function LandingHeader({
  props,
  handleRegisterClick,
  handleLoginClick,
}) {
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <StyledAppBar>
          <Box
            component="img"
            src={ZackInsightsWhiteLogo}
            alt="Custom Icon"
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
              width: "15vw", // Set desired width
            }}
          />
          <StyledToolbar>
            <CustomButton
              color="whitetext"
              variant="text"
              onClick={handleRegisterClick}
            >
              Sign Up
            </CustomButton>
            <CustomButton
              color="green"
              variant="contained"
              onClick={handleLoginClick}
            >
              Login
            </CustomButton>
          </StyledToolbar>
        </StyledAppBar>
      </HideOnScroll>
      <StyledToolbar />

      <LandingPage
        handleLoginClick={handleLoginClick}
        handleRegisterClick={handleRegisterClick}
      />
    </React.Fragment>
  );
}
