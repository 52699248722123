import React, { useState, useRef, useEffect } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Checkmark icon
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField"; // Add TextField component from Material UI
import { styled, keyframes } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import ChecklistRtlRoundedIcon from "@mui/icons-material/ChecklistRtlRounded";
import { fetchData } from "../../services/apiConfig";

// Slide-in animation for the TextField
const slideIn = keyframes`
  0% {
    transform: translateX(-20px); /* Start off 20px to the left */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* End at its normal position */
    opacity: 1;
  }
`;

const OutlinedIconButton = styled(IconButton)(
  ({ theme, color, selected, copied }) => ({
    "& svg": {
      fontSize: 18,
      fill: selected ? (color === "up" ? "#C8E6C9" : "#FFCDD2") : "none", // Lighter fill color for selected icons
      stroke: color === "up" ? "#39B44A" : "#E57373", // Darker stroke color
      strokeWidth: 1.5,
      transition: "transform 0.2s, fill 0.2s", // Smooth transition for hover and click
    },
    "&:hover svg": {
      transform: "scale(1.2)", // Slightly enlarge on hover
    },
  })
);

const OutlinedIconButtonCopy = styled(IconButton)(
  ({ theme, color, selected, copied }) => ({
    "& svg": {
      fontSize: 18,
      fill: "#1976D2", // Lighter fill color for selected icons
      strokeWidth: 1.5,
      transition: "transform 0.2s, fill 0.2s", // Smooth transition for hover and click
    },
    "&:hover svg": {
      transform: "scale(1.2)", // Slightly enlarge on hover
    },
  })
);

// Styled TextField with no border and a simple line
const SimpleLineTextField = styled(TextField)(
  ({ theme, selected, showMessageField }) => ({
    "& .MuiInputBase-root": {
      border: "none !important", // Remove default border
      borderRadius: 0, // Remove rounded corners
      padding: "0px", // Remove padding
      color: selected === "up" ? "#39B44A" : "#E57373",
      "&::before, &::after": {
        borderBottom: "1px solid grey !important", // Add a simple line border
      },
      "&:hover::before": {
        borderBottom: `1px solid ${
          selected === "up" ? "#39B44A" : "#E57373"
        } !important`, // Conditional hover line color
      },
      "&.Mui-focused::after": {
        borderBottom: `1px solid ${
          selected === "up" ? "#39B44A" : "#E57373"
        } !important`, // Conditional focused line color
      },
    },
    "& .MuiInputBase-input": {
      padding: "8px 0", // Ensure the text field's input has some spacing
      fontSize: "11px", // Set font size
      border: "none",
    },
    "&:hover .MuiInputBase-input": {
      padding: "8px 0", // Ensure the text field's input has some spacing
      fontSize: "11px", // Set font size
      border: "none",
    },
    animation: showMessageField ? `${slideIn} 0.3s ease-out` : "none", // Only animate when visible
  })
);

const FeedbackTile = ({ messageText, promptId }) => {
  const [selected, setSelected] = useState(null); // Track selected icon
  const [copied, setCopied] = useState(false); // Track whether the text has been copied
  const [message, setMessage] = useState(""); // Track the message text input
  const [submitted, setSubmitted] = useState(false); // Track if the message is submitted
  const [showMessageField, setShowMessageField] = useState(false); // Track visibility of the message field
  const inputRef = useRef(null); // Reference to the TextField

  const handleClick = (icon) => {
    setSelected(icon); // Set the selected icon (either "up" or "down")
    setShowMessageField(true); // Show the message field when an icon is selected
  };

  const handleCopy = () => {
    // Strip HTML tags from the messageText to get plain text
    const plainText =
      new DOMParser().parseFromString(messageText, "text/html").body
        .textContent || "";

    // Use the Clipboard API to copy the plain text
    navigator.clipboard
      .writeText(plainText)
      .then(() => {
        setCopied(true); // Change the icon to a checkmark when copied
        // console.log("Message copied to clipboard!"); // Optionally show a success message
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleKeyDown = (event) => {
    // Check if the Enter key is pressed
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission (if inside a form)
      setSubmitted(true); // Mark the message as submitted
      try {
        if (promptId) {
          fetchData("submitFeedback", "PUT", {
            prompt_id: promptId,
            feedback_action: selected === "up" ? "true" : "false",
            feedback_text: message,
          }).then((response) => {
            // console.log(response);
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const handleFeedbackSubmit = () => {
  //   console.log("feedback submitted by icon");
  //   setSubmitted(true);
  //   try {
  //     if (promptId) {
  //       fetchData("submitFeedback", "PUT", {
  //         prompt_id: promptId,
  //         feedback_action: selected === "up" ? "true" : "false",
  //         feedback_text: message,
  //       }).then((response) => {
  //         console.log(response);
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (showMessageField && inputRef.current) {
      inputRef.current.focus(); // Focus the text field when it appears
    }
  }, [selected, showMessageField]); // Runs when the message field becomes visible

  const handleBlur = () => {
    setShowMessageField(false); // Hide the message field when it loses focus
    try {
      if (promptId) {
        fetchData("submitFeedback", "PUT", {
          prompt_id: promptId,
          feedback_action: selected === "up" ? "true" : "false",
          feedback_text: message,
        }).then((response) => {
          // console.log(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {/* Render TextField when a thumbs up or thumbs down is selected */}
      {(selected === "up" || selected === "down") &&
        !submitted &&
        showMessageField && (
          <SimpleLineTextField
            inputRef={inputRef} // Reference to manage focus
            value={message}
            onChange={(e) => setMessage(e.target.value)} // Update message text
            onKeyDown={handleKeyDown} // Detect Enter key press
            variant="standard" // Use standard variant for underline style
            size="small"
            placeholder={
              selected === "up" ? "Share your thoughts" : "Help us improve"
            }
            selected={selected} // Pass selected prop to styled TextField
            showMessageField={showMessageField} // Trigger animation when visible
            onBlur={handleBlur} // Hide the field when it loses focus
            error={false}
            spellCheck={false}
            style={{
              marginRight: "8px", // Add some space between the text field and icons
              width: "200px", // Set a fixed width for the text field
            }}
            // InputProps={{
            //   endAdornment: (
            //     <IconButton
            //       aria-label="submit"
            //       onClick={handleFeedbackSubmit} // Submit when clicked
            //       style={{ padding: 8 }}
            //     >
            //       <KeyboardReturnIcon style={{ fontSize: 16 }} />
            //     </IconButton>
            //   ),
            // }}
          />
        )}

      {/* Display a checkmark icon if the message is submitted */}
      {submitted && (
        <ChecklistRtlRoundedIcon
          style={{ color: selected === "up" ? "#39B44A" : "#E57373" }}
        />
      )}

      {/* Thumbs Up Button with Tooltip */}
      {selected !== "down" && (
        <Tooltip title="Like">
          <OutlinedIconButton
            aria-label="thumbs up"
            color="up"
            selected={selected === "up"}
            onClick={() => handleClick("up")}
          >
            <ThumbUpIcon />
          </OutlinedIconButton>
        </Tooltip>
      )}

      {/* Thumbs Down Button with Tooltip */}
      {selected !== "up" && (
        <Tooltip title="Dislike">
          <OutlinedIconButton
            aria-label="thumbs down"
            color="down"
            selected={selected === "down"}
            onClick={() => handleClick("down")}
          >
            <ThumbDownIcon />
          </OutlinedIconButton>
        </Tooltip>
      )}

      {/* Copy Icon with Tooltip */}
      <Tooltip title={copied ? "Message copied!" : "Copy message"}>
        <OutlinedIconButtonCopy
          aria-label="copy"
          onClick={handleCopy}
          copied={copied} // Pass the copied state to change color and icon
        >
          {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
        </OutlinedIconButtonCopy>
      </Tooltip>
    </Box>
  );
};

export default FeedbackTile;
