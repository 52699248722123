import { styled } from "@mui/system"; // Import from @mui/system for better tree shaking
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

// Container for the TextField and Paragraph
export const StyledContainerTop = styled(Box)(({ theme }) => ({
  height: "calc(100dvh - 130px)",
  width: "100%",
  maxWidth: "100%",
  [theme.breakpoints.up("md")]: {
    height: "calc(100vh - 130px)",
    width: "100%",
    maxWidth: "100%",
  },
}));

// Container for the TextField and Paragraph
export const StyledContainer = styled(Box)(({ theme }) => ({
  marginTop: "auto",
  marginLeft: "0px",
  [theme.breakpoints.up("md")]: {
    marginLeft: "56px",
  },
}));

// Styled Paragraph component
export const StyledParagraph = styled(Typography)(({ theme }) => ({
  color: "#7D7D7D",
  fontFamily: "'Montserrat', sans-serif;",
  fontSize: "12px",
  margin: "10px 20px",
  fontWeight: 500,
  display: "flex",
  justifyContent: "center",
  "& span": {
    textDecoration: "underline",
  },
}));

// Styled Paragraph component
export const StyledPaper = styled(Paper)(
  ({ theme, open, isMobile, tickerDrawerOpen }) => ({
    padding: isMobile ? "0px" : open ? "0px 5vw" : "0px 5vw",
    display: "flex",
    flexDirection: "column",
    border: "none",
    boxShadow: "none",
    width:
      tickerDrawerOpen && !open
        ? "100%"
        : open && tickerDrawerOpen
        ? "100%"
        : open && !tickerDrawerOpen
        ? "100%"
        : "100%",
  })
);

// Container for messages
export const StyledMessageContainer = styled(Box)(({ theme }) => ({
  height: "calc(-240px + 100dvh)",
  display: "flex",
  flexDirection: "column",
  padding: "12px",
  [theme.breakpoints.up("md")]: {
    height: "calc(-240px + 100vh)",
    padding: "2px",
  },
}));

export const StyledHomeScreen = styled(Box)(({ theme }) => ({
  marginLeft: "56px",
}));

// Styled List component
export const StyledList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  scrollbarWidth: "none",
  paddingTop: "20px",
  paddingBottom: "20px",
}));

// Styled ListItem component
export const StyledListItem = styled(ListItem)(({ theme, isQuestion }) => ({
  alignItems: isQuestion ? "center" : "flex-start",
  padding: "0px",
  marginBottom: "15px",
}));

// Styled ListItemText component
export const StyledListItemText = styled(ListItemText)(
  ({ theme, isQuestion, isLoading }) => ({
    backgroundColor: isQuestion
      ? "#369902"
      : isLoading
      ? "#fff9f2"
      : "transparent",
    padding: isQuestion ? "0.75vw" : isLoading ? "1.2vw" : "0px 5px",
    color: isQuestion ? "#fff" : "#000",
    position: "relative",
    ...(isQuestion && {
      "&::after": {
        content: '""', // Empty content for the triangle
        position: "absolute",
        bottom: "-19px", // Position below the bubble
        left: 0, // Adjust to position the triangle
        right: 0,
        width: 0,
        height: 0,
        borderLeft: "0px solid transparent",
        borderRight: "20px solid transparent",
        borderTop: "20px solid #369902", // Same color as the bubble
      },
    }),

    ".answer-message-content": {
      lineHeight: 1.75,
      p: {
        margin: 0,
      },
      span: {
        backgroundColor: "#f0fff0",
      },
      question: {
        display: "block",
        alignItems: "center",
        // backgroundColor: "#fff9f2",
        marginTop: "10px",
        width: "fit-content",
        padding: "8px",
        // border: "2px solid #fff9f2",
        cursor: "pointer",
        background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
        backgroundColor: "#e0e0e0",
        borderRadius: "5px",
        "&::before": {
          content: '"+"', // Add the "+" symbol
          marginRight: "12px", // Space between "+" and the text
          fontWeight: "100", // Style for the "+" symbol
          fontSize: "22px",
          color: "#000", // Adjust color
          marginLeft: "12px",
          fontFamily: "auto",
        },
        "&:hover": {
          backgroundColor: "#cbcbcb",
          background: "linear-gradient(0deg, #cbcbcb 0%, #dfdfdf 100%)",
        },
      },
      service: {
        display: "block",
        alignItems: "center",
        backgroundColor: "rgb(237, 247, 237)",
        marginTop: "10px",
        width: "fit-content",
        padding: "5px",
        border: "2px solid rgb(237, 247, 237)",
        cursor: "pointer",
      },
      advisor: {
        display: "block",
        alignItems: "center",
        backgroundColor: "rgb(237, 247, 237)",
        color: "black",
        marginTop: "10px",
        width: "fit-content",
        padding: "5px 20px 5px 20px",
        cursor: "pointer",
        borderRadius: "5px",

        "&::before": {
          content: '"Zacks | INVESTMENT MANAGEMENT "',
          display: "block", // Ensures it's treated as a block-level element
          letterSpacing: "3px",
          marginBottom: "10px",
          fontFamily: '"SecondaryFont", serif',
          color: "#124734",
          boxShadow: "0 0.2px 0 0 #124734",
        },

        "&:hover": {
          backgroundColor: "rgb(210, 240, 210)",
        },
      },
      table: {
        border: "0.1px solid rgba(0, 0, 0, .1) !important",
        borderCollapse: "separate",
        borderSpacing: "0px 0px",
        marginBottom: ".25rem",
        marginTop: ".25rem",
        width: "100%",
        fontSize: ".875em",
        lineHeight: "1.7142857",
        tableLayout: "auto",
        borderColor: "inherit",
        textIndent: "0",
      },
      th: {
        borderBottom: "none",
        borderLeft: "0.3px solid rgba(0, 0, 0, .15)",
        backgroundColor: "#f0f4f9",
        padding: "2px",
      },
      td: {
        borderBottom: "0.3px solid rgba(0, 0, 0, 0.15)",
        borderLeft: "0.3px solid rgba(0, 0, 0, .15)",
        padding: "2px",
      },
      code: {
        display: "none",
      },
      pre: {
        display: "none",
      },
    },
    ".question-message-content": {
      lineHeight: 1.75,
      fontWeight: 500,
    },
    [theme.breakpoints.up("md")]: {
      padding: isQuestion ? "0.75vw" : isLoading ? "1.2vw" : "0px 25px",
    },
  })
);

// Styled Paragraph for no messages
export const StyledNoMessagesText = styled(Typography)(({ theme }) => ({
  backgroundClip: "text",
  color: "#369902",
  fontFamily: "'Montserrat', sans-serif;",
  fontSize: "25px",
  margin: 0,
  fontWeight: 500,
  letterSpacing: "1px",
}));

export const StyledSubheading = styled(Typography)(({ theme }) => ({
  color: "#5D5D5D",
  fontFamily: "'Montserrat', sans-serif;",
  fontSize: "5vw",
  // marginTop: "-10px",
  fontWeight: 500,
  lineHeight: "55px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "4vw", // 55% of viewport width for medium and larger screens
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3vw",
  },
}));
