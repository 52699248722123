import React from "react";
import { LinearProgress, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";

// Create a styled version of LinearProgress to customize animation speed
const SlowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  padding: "2vw",
  textAlign: "left",
  width: "100%", // Full width to fill the grid item
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
  backgroundColor: "#e0e0e0",
  // border: "1px solid #fff9f2",
  borderRadius: "5px",
  boxShadow: "none",
  "& .MuiLinearProgress-bar": {
    transition: "transform 5s ease-out", // Adjust duration to make it slower
    backgroundColor: "#e0e0e0",
    opacity: 0.1,
  },
}));

const CustomSuggestionProgress = (props) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: "0.4vw", padding: "0px 1vw 0px 1vw" }}
    >
      {[3, 2, 3].map((item, index) => (
        <Grid
          item
          xs={4} // Full width on small screens
          sm={4} // 50% width on small screens
          md={4} // 50% width on medium screens
          lg={4} // 50% width on large screens
          key={index}
        >
          <Paper
            elevation={3}
            sx={{
              // padding: "15px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              backgroundColor: "#e0e0e0",
              background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
              // border: "1px solid #fff9f2",
              borderRadius: "5px",
              boxShadow: "none",
              cursor: "pointer",
              alignSelf: index % 2 === 0 ? "flex-start" : "flex-end", // Alternate alignment for left and right
            }}
          >
            <SlowLinearProgress
              style={{ width: 100 * item }} // Multiply item value to set progress percentage
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomSuggestionProgress;
