import React, { useState } from "react";
import { Checkbox, Grid, Card, CardContent, Typography } from "@mui/material";
import {
  StyledDialog,
  StyledDialogTitle,
  StyledList,
  StyledListItem,
  StyledListItemIcon,
  StyledFiberManualRecordIcon,
  StyledDialogActions,
  StyledButton,
  StyledListItemText,
  StyledDialogContent,
  StyledFormControlLabel,
} from "./DisclaimerDialogStyled";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";

const pdfFiles = [
  {
    name: "ADV_Form.pdf",
    url: "https://reports.adviserinfo.sec.gov/reports/ADV/333798/PDF/333798.pdf",
  },
  {
    name: "Part_2_Brochures.pdf",
    url: "https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=937344",
  },
  {
    name: "Part_3_Customer_Relationship_Summary.pdf",
    url: "https://reports.adviserinfo.sec.gov/crs/crs_333798.pdf",
  },
];

const DisclaimerDialog = ({ open, onClose }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <StyledDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(); // Only close when it's not a backdrop click
        }
      }}
      aria-labelledby="service-dialog-title"
      aria-describedby="service-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <StyledDialogTitle id="service-dialog-title">
        Disclaimer
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledList>
          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <StyledFiberManualRecordIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Advice is general and not personalized to individual goals or risk tolerance." />
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <StyledFiberManualRecordIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Information may be inaccurate, incomplete, or outdated (AI can produce 'hallucinations')." />
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <StyledFiberManualRecordIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Recommendations are AI-generated with no human oversight." />
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <StyledFiberManualRecordIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Investment risk: You can lose money; past performance doesn't guarantee future results." />
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <StyledFiberManualRecordIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Data limitations and biases may affect AI-generated advice." />
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <StyledFiberManualRecordIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Don't rely solely on AI; consider professional advice for personal financial decisions." />
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <StyledFiberManualRecordIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Recommendations may not align with your individual financial situation or diversification needs; consider contacting Zacks Investment Management for complex personalized investment strategies and advisor services." />
          </StyledListItem>
        </StyledList>

        <Grid container spacing={2}>
          {pdfFiles.map((file, index) => (
            <>
              <Grid item xs={4} sm={4} md={4} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "4px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PictureAsPdfIcon
                      sx={{
                        fontSize: "2vw",
                        color: "#e91e63",
                      }}
                    />
                    <CardContent sx={{ flex: 1, maxWidth: "13vw" }}>
                      <Typography
                        variant="body1"
                        noWrap
                        title={file.name}
                        sx={{ fontWeight: "bold", fontSize: "0.75vw" }}
                      >
                        {file.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontSize: "0.75vw" }}
                      >
                        PDF
                      </Typography>
                    </CardContent>
                  </div>
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer" // For security reasons when using target="_blank"
                    style={{ textDecoration: "none" }} // Remove underline
                  >
                    <DownloadIcon
                      color="success"
                      style={{ cursor: "pointer", fontSize: 40 }} // Customize as needed
                    />
                  </a>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>

        {/* Add checkbox */}
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="success"
            />
          }
          label="I have read and understood the disclaimer."
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton
          onClick={onClose}
          color="success"
          variant="contained"
          disabled={!isChecked} // Disable until the checkbox is checked
        >
          Submit
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default DisclaimerDialog;
