import React from "react";
import { styled } from "@mui/system";
import { Button } from "@mui/material";

const fontFamily = "Inter, sans-serif";

// Define custom buttons with different styles
const GreenButton = styled(Button)(({ theme }) => ({
  fontFamily,
  backgroundColor: "#369902",
  color: "#fff",
  textTransform: "none",
  // width: "100px",
  borderRadius: "24px",
  fontSize: "4vw",
  fontWeight: "normal",
  "&:hover": {
    backgroundColor: "#36b02d",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "3vw", // 55% of viewport width for medium and larger screens
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1vw",
  },
}));

// Define custom buttons with different styles
const HeroGreenButton = styled(Button)(({ theme }) => ({
  fontFamily,
  backgroundColor: "#369902",
  color: "#fff",
  textTransform: "none",
  padding: "0.8rem 2.5rem",
  fontSize: "4vw",
  fontWeight: "bold",
  borderRadius: "2rem",
  marginBottom: "2rem",
  "&:hover": {
    backgroundColor: "#36b02d",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.75vw", // 55% of viewport width for medium and larger screens
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25vw",
  },
}));

const OutlineButton = styled(Button)(({ theme }) => ({
  fontFamily,
  backgroundColor: "white",
  color: "#000",
  textTransform: "none",
  width: "100px",
  border: "2px solid #369902",
  borderRadius: "24px",
  fontSize: "16px",
  fontWeight: "normal",
  "&:hover": {
    backgroundColor: "#f0f4f9",
  },
}));

const LogButton = styled(Button)(({ theme }) => ({
  // fontFamily,
  backgroundColor: "#369902",
  color: "#fff",
  textTransform: "none",
  width: "90%",
  borderRadius: "12px",
  padding: "10px",
  fontWeight: "normal",
  fontSize: "16px",
  "&:hover": {
    backgroundColor: "#0f611c",
  },
}));

const BlueButton = styled(Button)(({ theme }) => ({
  fontFamily,
  backgroundColor: "#2196f3",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#1976d2",
  },
}));

const TextButton = styled(Button)(({ theme }) => ({
  fontFamily,
  textTransform: "none",
  letterSpacing: "0px",
  justifyContent: "flex-end",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
}));

const BlackTextButton = styled(Button)(({ theme }) => ({
  fontFamily,
  textTransform: "none",
  letterSpacing: "0px",
  minWidth: "0px",
  color: "#000",
  "&:hover": {
    // backgroundColor: "#f0f4f9",
    textDecoration: "underline",
  },
}));

const WhiteTextButton = styled(Button)(({ theme }) => ({
  fontFamily,
  fontSize: "4vw",
  textTransform: "none",
  letterSpacing: "0px",
  minWidth: "0px",
  color: "#fff",
  "&:hover": {
    textDecoration: "underline",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "3vw", // 55% of viewport width for medium and larger screens
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1vw",
  },
}));

const CustomButton = ({ color, ...props }) => {
  switch (color) {
    case "green":
      return <GreenButton {...props} />;
    case "herogreen":
      return <HeroGreenButton {...props} />;
    case "log":
      return <LogButton {...props} />;
    case "blue":
      return <BlueButton {...props} />;
    case "text":
      return <TextButton {...props} />;
    case "blacktext":
      return <BlackTextButton {...props} />;
    case "whitetext":
      return <WhiteTextButton {...props} />;
    case "outline":
      return <OutlineButton {...props} />;
    default:
      return <Button {...props} />;
  }
};

export default CustomButton;
