import {
  Typography,
  Box,
  IconButton,
  Divider,
  Chip,
  Grid,
  Button,
  Paper,
  Stack,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  DrawerHeader,
  Stacker,
  StyledGridLabel,
  StyledGridValue,
} from "./RightSideDrawerStyles"; // Import styled components
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StockInfoCard = ({ tickerData, selectedStock }) => {
  const stockData = tickerData.find((item) => item.Ticker === selectedStock);
  const rankColors = {
    1: "success",
    2: "success",
    3: "warning",
    4: "error",
    5: "error",
  };
  const buy = stockData["Analyst Ratings"]["% Total Buy Ratings"];
  const hold = stockData["Analyst Ratings"]["% Hold Ratings"];
  const sell = stockData["Analyst Ratings"]["% Total Sell Ratings"];
  const [expanded, setExpanded] = useState({
    group1: true,
    group2: true,
    group3: true,
    group4: true,
  });

  // Find the max rating and its label
  const maxRating = Math.max(buy, hold, sell);
  const maxLabel =
    maxRating === buy ? "Buy" : maxRating === hold ? "Hold" : "Sell";
  const zacksRankInfo = [
    { rank: 1, meaning: "Strong Buy" },
    { rank: 2, meaning: "Buy" },
    { rank: 3, meaning: "Hold" },
    { rank: 4, meaning: "Sell" },
    { rank: 5, meaning: "Strong Sell" },
  ];

  const handleToggle = (group) => {
    setExpanded((prevState) => ({
      ...prevState,
      [group]: !prevState[group],
    }));
  };

  return (
    <Box p={2}>
      <Typography variant="h6" color="textPrimary">
        {stockData["Company Name"]} ({stockData.Ticker})
      </Typography>
      <Typography variant="h4" sx={{ color: "#369902" }}>
        ${stockData["Stock Performance"]["Last Closing Price"]}{" "}
        <Typography variant="body2" component="span" sx={{ color: "#369902" }}>
          USD
        </Typography>
      </Typography>
      <Typography
        variant="subtitle2"
        color={
          stockData["Stock Performance"]["% Change YTD"] >= 0
            ? "#124734"
            : "#d32f2f"
        }
      >
        {stockData["Stock Performance"]["% Change YTD"]}% YTD Change
      </Typography>

      <Box mt={2} mb={2}>
        {/* <Typography variant="h6" gutterBottom>
          Analyst Reports
        </Typography> */}
        <Box variant="outlined" sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography variant="body2">Zacks Rank: </Typography>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" spacing={1}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <Chip
                    key={num}
                    label={num}
                    color={
                      stockData["Analyst Ratings"]["Zacks Rank"] === num
                        ? rankColors[num]
                        : "default"
                    }
                    size="small"
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Analyst Consensus:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2">
                {stockData["Analyst Ratings"]["Analyst Consensus"]}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Target Price:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2">
                ${stockData["Analyst Ratings"]["Zacks Target Price"]}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Sentiment:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Tooltip
                title={`Buy: ${buy}%, Hold: ${hold}%, Sell: ${sell}%`}
                arrow
                placement="top"
              >
                <Box
                  display="flex"
                  width="100%"
                  height={30}
                  // borderRadius={2}
                  overflow="hidden"
                >
                  {/* Buy Section */}
                  <Box
                    sx={{
                      width: `${buy}%`,
                      backgroundColor: "green",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "14px",
                      cursor: "pointer",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {maxRating === buy && `${maxLabel} ${maxRating}%`}
                  </Box>

                  {/* Hold Section */}
                  <Box
                    sx={{
                      width: `${hold}%`,
                      backgroundColor: "#FFD700",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "black",
                      fontSize: "14px",
                      cursor: "pointer",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {maxRating === hold && `${maxLabel} ${maxRating}%`}
                  </Box>

                  {/* Sell Section */}
                  <Box
                    sx={{
                      width: `${sell}%`,
                      backgroundColor: "#d32f2f",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "14px",
                      cursor: "pointer",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {maxRating === sell && `${maxLabel} ${maxRating}%`}
                  </Box>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* <Divider /> */}

      <Box mt={2}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
            padding: "10px",
            borderRadius: "5px",
            color: "black",
            marginLeft: "-10px",
            marginRight: "-10px",
          }}
        >
          Company Overview
        </Typography>

        <Grid container spacing={2}>
          {[
            {
              label: "Exchange",
              value: stockData["Company Overview"]["Exchange"],
            },
            {
              label: "Market Cap",
              value: `$${stockData["Company Overview"]["Market Cap (Million$)"]}M`,
            },
            {
              label: "S&P 500 In/Out",
              value: `${stockData["Company Overview"]["S&P 500 In/Out"]}`,
            },
            {
              label: "Shares Outstanding",
              value: `${stockData["Company Overview"]["Shares Outstanding"]}`,
            },
            {
              label: "Beta",
              value: `${stockData["Company Overview"]["Beta"]}`,
            },
            {
              label: "Institutional Holding",
              value: `${stockData["Company Overview"]["Institutional Hold %"]}%`,
            },
          ].map((item, index) => (
            <>
              <Grid
                item
                xs={6}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <StyledGridLabel>{item.label}</StyledGridLabel>
                <StyledGridValue align="right">{item.value}</StyledGridValue>
              </Grid>
            </>
          ))}
        </Grid>
      </Box>

      {/* <Divider /> */}

      <Box mt={2}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
            padding: "10px",
            borderRadius: "5px",
            color: "black",
            marginLeft: "-10px",
            marginRight: "-10px",
          }}
        >
          Stock Performance
        </Typography>
        <Grid container spacing={2}>
          {[
            {
              label: "Last Closing Price",
              value: `$${stockData["Stock Performance"]["Last Closing Price"]}`,
            },
            {
              label: "Average Volume",
              value: stockData["Stock Performance"]["Average Volume (20 Days)"],
            },

            {
              label: "52-Week High",
              value: `$${stockData["Stock Performance"]["52-Week High"]}`,
            },
            {
              label: "%1 Week Change",
              value: `${stockData["Stock Performance"]["% Change (1 Week)"]}%`,
            },
            {
              label: "52-Week Low",
              value: `$${stockData["Stock Performance"]["52-Week Low"]}`,
            },

            {
              label: "% 4 Week Change",
              value: `${stockData["Stock Performance"]["% Change (4 Weeks)"]}%`,
            },
            {
              label: "P/52W Range",
              value: `${stockData["Stock Performance"]["Price to 52-Week Range"]}%`,
            },
            {
              label: "% 12 Week Change",
              value: `${stockData["Stock Performance"]["% Change (12 Weeks)"]}%`,
            },
            {
              label: "Rel. S&P YTD",
              value: `${stockData["Stock Performance"]["Rel. Change S&P YTD"]}%`,
            },

            {
              label: "% Change YTD",
              value: `${stockData["Stock Performance"]["% Change YTD"]}%`,
            },
          ].map((item, index) => (
            <>
              <Grid
                item
                xs={6}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <StyledGridLabel>{item.label}</StyledGridLabel>
                <StyledGridValue align="right">{item.value}</StyledGridValue>
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
      {/* <Divider /> */}

      <Box mt={2}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
            padding: "10px",
            borderRadius: "5px",
            color: "black",
            marginLeft: "-10px",
            marginRight: "-10px",
          }}
        >
          Company Financials
        </Typography>

        <Grid container spacing={2}>
          {[
            {
              label: "P/E Trailing 12M",
              value: stockData["Financials"]["P/E Trailing 12M"],
            },
            {
              label: "PEG Ratio",
              value: stockData["Financials"]["PEG Ratio"],
            },
            {
              label: "Price to Book",
              value: stockData["Financials"]["Price to Book"],
            },
            {
              label: "P/E Current Year F1",
              value: stockData["Financials"]["P/E Current Year F1"],
            },
            {
              label: "Return on Equity",
              value: `${stockData["Financials"]["Return on Equity"]}%`,
            },
            {
              label: "Price to Cash",
              value: stockData["Financials"]["Price to Cash"],
            },
            {
              label: "P/E Next Year F2",
              value: stockData["Financials"]["P/E Next Year F2"],
            },
            {
              label: "Net Margin",
              value: stockData["Financials"]["Net Margin"] ?? "N/A",
            },
            {
              label: "Current Ratio",
              value: stockData["Financials"]["Current Ratio"],
            },
            {
              label: "Dividend Yield",
              value: `${stockData["Financials"]["Dividend Yield"]}%`,
            },
            {
              label: "Debt to Equity",
              value: stockData["Financials"]["Debt to Equity"] ?? "N/A",
            },
            {
              label: "Price to Sales",
              value: stockData["Financials"]["Price to Sales"],
            },
          ].map((item, index) => (
            <Grid
              item
              xs={6}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <StyledGridLabel>{item.label}</StyledGridLabel>
              <StyledGridValue align="right">{item.value}</StyledGridValue>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* <Divider /> */}

      <Box mt={2}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
            padding: "10px",
            borderRadius: "5px",
            color: "black",
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          Eps Estimates And Surprises
        </Typography>
        <>
          {/* Group 1 */}
          <Accordion
            expanded={expanded.group1}
            onChange={() => handleToggle("group1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="group1-content"
              id="group1-header"
            >
              <Typography sx={{ fontWeight: "500" }}>
                Last Quarter EPS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {[
                  {
                    label: "Est. EPS Last Qtr Q0",
                    value:
                      stockData["Eps Estimates And Surprises"].last_quarter_eps[
                        "Est. EPS Last Qtr Q0"
                      ],
                  },
                  {
                    label: "Act. EPS Last Qtr Q0",
                    value:
                      stockData["Eps Estimates And Surprises"].last_quarter_eps[
                        "Act. EPS Last Qtr Q0"
                      ],
                  },

                  {
                    label: "EPS Surprise Avg (4Q)",
                    value: `${stockData["Eps Estimates And Surprises"].last_quarter_eps["EPS Surprise Avg (4Q)"]}`,
                  },
                  {
                    label: "Last Qtr EPS Surprise %",
                    value: `${stockData["Eps Estimates And Surprises"].last_quarter_eps["Last Qtr EPS Surprise %"]}%`,
                  },
                ].map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <StyledGridLabel>{item.label}</StyledGridLabel>
                    <StyledGridValue align="right">
                      {item.value}
                    </StyledGridValue>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Group 2 */}
          <Accordion
            expanded={expanded.group2}
            onChange={() => handleToggle("group2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="group2-content"
              id="group2-header"
            >
              <Typography sx={{ fontWeight: "500" }}>
                Quarterly Estimates
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {[
                  {
                    label: "Est. EPS Curr Qtr Q1",
                    value:
                      stockData["Eps Estimates And Surprises"]
                        .quarterly_estimates["Est. EPS Curr Qtr Q1"],
                  },
                  {
                    label: "Est. EPS Next Qtr Q2",
                    value:
                      stockData["Eps Estimates And Surprises"]
                        .quarterly_estimates["Est. EPS Next Qtr Q2"],
                  },
                  {
                    label: "EPS Est. % Chg Curr Qtr",
                    value: `${stockData["Eps Estimates And Surprises"].quarterly_estimates["EPS Est. % Chg Curr Qtr (4W)"]}%`,
                  },
                ].map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <StyledGridLabel>{item.label}</StyledGridLabel>
                    <StyledGridValue align="right">
                      {item.value}
                    </StyledGridValue>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Group 3 */}
          <Accordion
            expanded={expanded.group3}
            onChange={() => handleToggle("group3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="group3-content"
              id="group3-header"
            >
              <Typography sx={{ fontWeight: "500" }}>
                Yearly Estimates
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {[
                  {
                    label: "Est. EPS Growth",
                    value:
                      stockData["Eps Estimates And Surprises"].yearly_estimates[
                        "Est. EPS Growth"
                      ],
                  },
                  {
                    label: "Est. EPS Curr Year F1",
                    value:
                      stockData["Eps Estimates And Surprises"].yearly_estimates[
                        "Est. EPS Curr Year F1"
                      ],
                  },
                  {
                    label: "Est. EPS Next Year F2",
                    value:
                      stockData["Eps Estimates And Surprises"].yearly_estimates[
                        "Est. EPS Next Year F2"
                      ],
                  },
                  {
                    label: "EPS Est. % Chg Curr Year (4W)",
                    value: `${stockData["Eps Estimates And Surprises"].yearly_estimates["EPS Est. % Chg Curr Year (4W)"]}%`,
                  },
                  {
                    label: "EPS Est. % Chg Long-Term Growth (4W)",
                    value: `${stockData["Eps Estimates And Surprises"].yearly_estimates["EPS Est. % Chg Long-Term Growth (4W)"]}%`,
                  },
                  {
                    label: "EPS Est. % Chg Next Year (4W)",
                    value: `${stockData["Eps Estimates And Surprises"].yearly_estimates["EPS Est. % Chg Next Year (4W)"]}%`,
                  },
                ].map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <StyledGridLabel>{item.label}</StyledGridLabel>
                    <StyledGridValue align="right">
                      {item.value}
                    </StyledGridValue>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Group 4 - Important Dates */}
          <Accordion
            expanded={expanded.group4}
            onChange={() => handleToggle("group4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="group4-content"
              id="group4-header"
            >
              <Typography sx={{ fontWeight: "500" }}>
                Important Dates
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {[
                  {
                    label: "Last Earnings Report Date",
                    value:
                      stockData["Eps Estimates And Surprises"].important_dates[
                        "Last earnings report date"
                      ],
                  },
                  {
                    label: "Fiscal Year End Date",
                    value:
                      stockData["Eps Estimates And Surprises"].important_dates[
                        "Fiscal year end date"
                      ],
                  },
                  {
                    label: "Upcoming Earnings Report Date",
                    value:
                      stockData["Eps Estimates And Surprises"].important_dates[
                        "Upcoming or next earnings report date"
                      ],
                  },
                ].map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <StyledGridLabel>{item.label}</StyledGridLabel>
                    <StyledGridValue align="right">
                      {item.value}
                    </StyledGridValue>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>

        <Divider />
        {/* <Box mt={2}>
          <Grid container spacing={1}>
            {[
              {
                label: "Next Earnings Report Date",
                value:
                  stockData["Eps Estimates And Surprises"][
                    "Upcoming or next earnings report date"
                  ],
              },
              {
                label: "Last Earnings Report Date",
                value:
                  stockData["Eps Estimates And Surprises"][
                    "Last earnings report date"
                  ],
              },
            ].map((item, index) => (
              <Grid
                item
                xs={12}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <StyledGridLabel sx={{ fontWeight: 500 }}>
                  {item.label}
                </StyledGridLabel>
                <StyledGridValue align="right">{item.value}</StyledGridValue>
              </Grid>
            ))}
          </Grid>
        </Box> */}
      </Box>
    </Box>
  );
};

const RightSideDrawer = ({
  open,
  onClose,
  onOpen,
  handleDrawerClose,
  tickerData,
}) => {
  const [selectedStock, setSelectedStock] = useState(
    tickerData[tickerData.length - 1].Ticker
  );

  const handleStockChange = (symbol) => {
    // Find the selected stock data based on the ticker symbol
    const stock = tickerData.find((item) => item.Ticker === symbol);
    if (stock) {
      setSelectedStock(stock.Ticker); // Update the selected stock
    }
    // Open the drawer if it's not already open
    if (!open) {
      onOpen();
    }
  };

  useEffect(() => {
    setSelectedStock(tickerData[tickerData.length - 1].Ticker);
  }, [tickerData]); // Runs when the messaoge field becomes visible

  return (
    <Drawer variant="permanent" anchor="right" open={open} onClose={onClose}>
      <DrawerHeader>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={open ? onClose : onOpen}
          edge="end"
          sx={{ color: "lightgray" }}
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </DrawerHeader>
      <Stacker
        open={open}
        spacing={open ? 2 : 1}
        direction={open ? "row" : "column"}
      >
        {tickerData.map((stock) => (
          <Button
            key={stock.Ticker}
            variant={selectedStock === stock.Ticker ? "contained" : "outlined"}
            onClick={() => handleStockChange(stock.Ticker)}
            color="success"
            sx={{
              border: 0,
              margin: "0px !important",
              borderRadius: 0,
              backgroundColor:
                selectedStock === stock.Ticker ? "#124734" : "transparent",
              color: selectedStock === stock.Ticker ? "white" : "inherit",
              borderRight: "1px solid rgb(92,92,92)",
              "&:hover": {
                backgroundColor:
                  selectedStock === stock.Ticker
                    ? "#0f2e1f"
                    : "rgba(18, 55, 36, 0.1)",
              },
            }}
          >
            {stock.Ticker}
          </Button>
        ))}
      </Stacker>
      {open && (
        <StockInfoCard tickerData={tickerData} selectedStock={selectedStock} />
      )}
    </Drawer>
  );
};

export default RightSideDrawer;
