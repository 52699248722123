import React from "react";
import { Avatar } from "@mui/material";

const CustomAvatar = ({ src, alt }) => {
  return (
    <Avatar
      alt={alt}
      src={src}
      sx={{
        borderRadius: "0",
        width: "auto",
        height: "auto",
        maxWidth: "25px",
        marginLeft: "8px",
        marginTop: "8px",
      }}
    />
  );
};

export default CustomAvatar;
