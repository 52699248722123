import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import zackNewLogo from "../../assests/zackNewLogo.png";
import { Card, CardContent, Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const pdfFiles = [
  {
    name: "ADV_Form.pdf",
    url: "https://reports.adviserinfo.sec.gov/reports/ADV/333798/PDF/333798.pdf",
  },
  {
    name: "Part_2_Brochures.pdf",
    url: "https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=937344",
  },
  {
    name: "Part_3_Customer_Relationship_Summary.pdf",
    url: "https://reports.adviserinfo.sec.gov/crs/crs_333798.pdf",
  },
];

const Disclosures = () => {
  return (
    <Container maxWidth="md">
      {/* Logo Section */}
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mt: 4 }}
        style={{ cursor: "pointer" }}
        onClick={() => window.location.replace("/")}
      >
        <Grid item>
          <Box
            component="img"
            src={zackNewLogo} // Replace with your logo path
            alt="Logo"
            sx={{ width: 50, height: 38 }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="div">
            ZACKS
          </Typography>
        </Grid>
      </Grid>

      {/* Terms and Conditions Content */}
      <Box mt={4} mb={4}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          style={{ display: "flex", justifyContent: "center" }}
        >
          SEC Disclosures and ADV Forms
        </Typography>
        <Typography variant="body1" paragraph>
          Zacks Intelligence LLC (“Zacks Intelligence”) was founded in 2024 and
          has been a registered investment adviser with the United States
          Securities & Exchange Commission since December 2024. Zacks
          Intelligence is a wholly owned subsidiary of Zacks Investment
          Research, Inc (“ZIR”). Zacks Intelligence’s advisory business is
          limited to providing individuals (each referred to as a “User”) with
          an interactive online platform at https://zacksinsight.com (the
          “Site”). Through use of artificial intelligence and large language
          models, the Site generates investment recommendations, investment
          guidance, and financial/market related information based on User
          queries (the “Service”). The Service is not tailored to any User’s
          personal financial needs/goals, individual circumstances, or risk
          tolerance, and depending on the nature of a query, the Service is
          provided using data from ZIR research reports, other data from Zacks
          Intelligence’s affiliates, general financial principles, and/or other
          historical and general data. Zacks Intelligence provides the Service
          solely through its interactive website. Zacks Intelligence does not
          meet with Users at its office. Zacks Intelligence does not manage any
          assets or participate in any wrap fee programs. As of November 8,
          2024, Zacks Intelligence managed $0 on behalf of any clients. <br />{" "}
          <br />
          As a Registered Investment Advisor (RIA), transparency and compliance
          are at the core of our operations. Below, you will find our
          SEC-mandated disclosures, including Form ADV, which outlines important
          information about our advisory services and business practices. These
          documents are provided to ensure our clients have access to the
          details they need to make informed decisions.
        </Typography>
        {/* <Typography variant="h6" component="h2" gutterBottom>
          1. ADV Form */}
        <Grid container spacing={2} style={{ padding: "2vw 0px" }}>
          {pdfFiles.map((file, index) => (
            <>
              {/* <Typography variant="h6" component="h2" gutterBottom>
                {index + 1}. {file.name}
              </Typography> */}
              <Grid item xs={12} sm={12} md={12} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "16px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      fontSize: "3rem",
                      color: "#e91e63",
                      marginRight: "16px",
                    }}
                  />
                  <CardContent sx={{ flex: 1 }}>
                    <Typography
                      variant="body1"
                      noWrap
                      title={file.name}
                      sx={{ fontWeight: "bold" }}
                    >
                      {file.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      PDF
                    </Typography>
                  </CardContent>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    href={file.url}
                    download
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Security best practice
                  >
                    Download
                  </Button>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>
        {/* </Typography> */}
        <Typography variant="body1" paragraph>
          Zacks Intelligence - Customer Support - <strong> 312-265-9157</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, feel free to contact us.
        </Typography>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          mt: "auto",
          width: "100%",
          textAlign: "center",
          borderTop: "1px solid #ccc",
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} Zacks intelligence. All rights reserved.
        </Typography>
        <Typography variant="body2">
          Zacks Intelligence LLC 10 S. Riverside Plaza, Suite 1600 Chicago, IL
          60606 (312)265-9157
        </Typography>
      </Box>
    </Container>
  );
};

export default Disclosures;
