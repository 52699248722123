import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import CloseIcon from "@mui/icons-material/Close";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import CustomHotHandOffLoader from "../atoms/Progress/CustomHotHandOffLoader";

import { fetchAuthData } from "../../services/apiConfig";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const HotHandOff = ({ open, handleClose }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [active, setActive] = useState("left");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDisclaimer = () => {
    setIsExpanded(!isExpanded);
  };
  const email = localStorage.getItem("email");
  const handleChatClick = async () => {
    // console.log("Chat with expert clicked");
    setFormSubmitting(true);
    try {
      // Simulate network delay to test token verification
      const response = await fetchAuthData("hotHandoff", "POST", {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        reference_number: "string",
      });
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 2200));
      setFormSubmitted(true);
    }
  };
  const handleCloseClick = () => {
    setFormSubmitted(false);
    setFormSubmitting(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="service-dialog-title"
      aria-describedby="service-dialog-description"
      maxWidth={formSubmitted ? "sm" : "lg"}
      fullWidth
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleCloseClick}
        sx={{
          position: "absolute",
          right: "20px",
          top: "10px",
          color: "lightgray",
        }}
      >
        <CloseIcon />
      </IconButton>
      {formSubmitted ? (
        <>
          {" "}
          <DialogTitle
            id="service-dialog-title"
            sx={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "column", // Stack items vertically
              alignItems: "center", // Center horizontally
              justifyContent: "center", // Center vertically
            }}
          >
            <CheckCircleRoundedIcon
              sx={{ fontSize: "50px", color: "success.main" }} // Adjust font size
            />
            <Typography variant="h6" style={{ margin: "0px" }}>
              Request submitted!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography id="service-dialog-description" sx={{ mb: 2 }}>
              Thank you for choosing Zacks Investment Management. We will call
              you back shortly.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="success"
              variant="contained"
              onClick={handleCloseClick}
              sx={{
                textTransform: "none",
                padding: "10px 60px",
                borderRadius: 2,
                fontWeight: "400",
              }}
            >
              Continue to chat <span style={{ marginLeft: "20px" }}></span>
            </Button>
          </DialogActions>
          <DialogContent></DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="service-dialog-title" sx={{ marginTop: "15px" }}>
            Take Your Portfolio to the Next Level
          </DialogTitle>
          <DialogContent>
            {/* Subtitle */}
            <Typography
              variant="body1"
              paragraph
              style={{ marginBottom: "2px" }}
            >
              <span style={{ fontStyle: "italic", fontWeight: "500" }}>
                Zacks Investment Management
              </span>{" "}
              specializes in helping individuals with portfolios greater than{" "}
              <strong>$750K</strong> tackle complex financial challenges with
              sophisticated strategies tailored to your unique goals; whether
              you're seeking to optimize growth, manage risk, or align your
              portfolio with specific objectives.
            </Typography>
            {/* Bullet Points */}
            <Typography
              variant="body1"
              paragraph
              style={{ marginBottom: "2px" }}
            >
              <span style={{ fontStyle: "italic", fontWeight: "500" }}>
                Zacks Investment Management
              </span>{" "}
              industry-leading services and strategies have been recognized with
              numerous accolades, including:
            </Typography>
            <List sx={{ padding: "0px 20px" }}>
              <ListItem disableGutters style={{ padding: "0px" }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  style={{ display: "inline-flex", margin: "0px" }}
                  primary="PSN’s Manager of the Decade and 6-Star Ratings"
                  secondary={
                    <Tooltip
                      title="*PSN 6-STAR RATING: These ratings were awarded by PSN on 8/19/2024 in respect of the period from 7/1/2019 to 6/30/2024. We do not compensate PSN to obtain this rating. However, we pay compensation to PSN to use their logo in connection with advertising this rating. Please see full disclosure at end of this document."
                      arrow
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: "18px",
                          position: "relative",
                          left: 0,
                          top: "5px",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "-10px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem disableGutters style={{ padding: "0px" }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  style={{ display: "inline-flex" }}
                  primary="CNBC’s Top 100 Advisory Firms"
                  secondary={
                    <Tooltip
                      title="*CNBC RANKING: This ranking was awarded by CNBC on 10/2/2024 in respect of the calendar year period of 1/1/2024 to 12/31/2024. We do not compensate CNBC to obtain this rating. However, we pay compensation to CNBC to use their logo in connection with advertising this ranking. Please see full disclosure at end of this document."
                      arrow
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: "18px",
                          position: "relative",
                          left: 0,
                          top: "5px",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "-10px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem disableGutters style={{ padding: "0px" }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  style={{ display: "inline-flex" }}
                  primary="Multiple top rankings by Morningstar"
                  secondary={
                    <Tooltip
                      title="These ratings were awarded by Morningstar on 10/1/2024 in respect of the period from strategy inception to 9/30/2024 (Inception Dates: All Cap- 2/1/1995, Focus Growth- 2/1/2003, Dividend- 4/1/2004, Mid and Small Cap- 5/1/2009). We do not compensate Morningstar to obtain this rating. However, we pay compensation to Morningstar to use their logo in connection with advertising this rating. Please see full disclosure at end of this document."
                      arrow
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: "18px",
                          position: "relative",
                          left: 0,
                          top: "5px",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "-10px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </ListItem>
            </List>
            <Typography variant="body1" paragraph>
              Please provide your contact information and an advisor will reach
              out to you in the next few days.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #ddd",
                mb: 2,
              }}
            >
              <Typography
                variant="button"
                sx={{
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px 16px",
                  color: active === "left" ? "#369902" : "grey",
                  borderBottom:
                    active === "left" ? "2px solid #369902" : "transparent",
                }}
                onClick={() => setActive("left")}
              >
                Get a callback
              </Typography>
              {/* <Typography
                variant="button"
                sx={{
                  maxWidth: formSubmitting ? "0px" : "500px",
                  cursor: "pointer",
                  flex: 1,
                  padding: "0px 16px",
                  color: active === "right" ? "#369902" : "grey",
                  borderBottom:
                    active === "right" ? "2px solid #369902" : "transparent",
                  animation: formSubmitting
                    ? `${shrinkAnimation} 2.5s forwards`
                    : "none",
                }}
                onClick={() => setActive("right")}
              >
                {formSubmitting ? "" : "Schedule a call"}
              </Typography> */}
            </Box>
            <Box sx={{ padding: 2, borderRadius: 1 }}>
              {active === "left" ? (
                <>
                  <Typography variant="body1">
                    Please share your details, and we'll arrange a callback at
                    your convenience.
                  </Typography>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "77px",
                    }}
                  >
                    <Box>
                      <Box
                        display="flex"
                        gap={2} // Spacing between text fields
                        mb={2} // Margin bottom for spacing between rows
                      >
                        <TextField
                          id="first-name"
                          // label="First Name"
                          placeholder="First Name"
                          variant="standard"
                          fullWidth
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          sx={{
                            flexDirection: "column-reverse",
                            "& .MuiInput-underline:hover:not(.Mui-disabled)::before":
                              {
                                borderBottom: "2px solid #369902", // Green border on hover
                              },
                            "& .MuiInput-underline.Mui-focused::after": {
                              borderBottom: "2px solid #369902", // Green border on focus
                            },
                          }}
                        />
                        <TextField
                          id="last-name"
                          // label="Last Name"
                          placeholder="Last Name"
                          variant="standard"
                          fullWidth
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          sx={{
                            flexDirection: "column-reverse",
                            "& .MuiInput-underline:hover:not(.Mui-disabled)::before":
                              {
                                borderBottom: "2px solid #369902", // Green border on hover
                              },
                            "& .MuiInput-underline.Mui-focused::after": {
                              borderBottom: "2px solid #369902", // Green border on focus
                            },
                          }}
                        />
                        <TextField
                          id="phone-number"
                          variant="standard"
                          fullWidth
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +01
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            inputMode: "numeric", // Ensures numeric keypad on mobile
                            pattern: "[0-9]*", // Allows only numbers
                          }}
                          sx={{
                            flexDirection: "column-reverse",
                            "& .MuiInput-underline:hover:not(.Mui-disabled)::before":
                              {
                                borderBottom: "2px solid #369902", // Green border on hover
                              },
                            "& .MuiInput-underline.Mui-focused::after": {
                              borderBottom: "2px solid #369902", // Green border on focus
                            },
                          }}
                        />
                        {formSubmitting ? (
                          <div>
                            <Button
                              onClick={handleChatClick}
                              variant="contained"
                              color="success"
                              sx={{
                                textTransform: "none",
                                borderRadius: 2,
                                padding: "10px 90px",
                                display: "flex",
                              }}
                            >
                              Submitting
                              <span style={{ marginLeft: "20px" }}>
                                <CustomHotHandOffLoader />
                              </span>
                            </Button>
                          </div>
                        ) : (
                          <Button
                            onClick={handleChatClick}
                            variant="contained"
                            color="success"
                            sx={{
                              textTransform: "none",
                              borderRadius: 2,
                              padding: "10px 90px",
                            }}
                          >
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </DialogActions>
                  <DialogContent style={{ padding: "0px" }}>
                    <Typography variant="caption" color="text.secondary">
                      * By submitting, you authorize Zacks Insight and its
                      affiliates can call or text me for any purpose at any
                      number provided, including via automatic phone dialing
                      system and/or pre-recorded voice, and such consent is not
                      required to purchase goods or services as I may always
                      call directly at 800.767.3771 ext. 9339.
                    </Typography>
                    <br />
                    <div style={{ display: "inline-grid" }}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        style={{
                          textOverflow: "ellipsis",
                          maxHeight: isExpanded ? "max-content" : "40px",
                          overflow: "hidden",
                          display: "inline-block",
                        }}
                      >
                        <strong>Disclosures: </strong>
                        CNBC RANKING: This information is distributed for
                        informational purposes only; to notify readers of a CNBC
                        ranking. CNBC has no affiliation with Zacks, but rather
                        evaluates investment manager performance on an objective
                        basis. Criteria: CNBC enlisted data provider AccuPoint
                        Solutions to assist with the ranking of registered
                        investment advisors for its 2024 FA 100 list. The
                        methodology consisted of first analyzing a variety of
                        core data points from AccuPoint Solutions’ proprietary
                        database of registered investment advisors. This
                        analysis used an initial list of 40,896 RIA firms from
                        the Securities and Exchange Commission regulatory
                        database. Through a process, the list was eventually cut
                        to 903 RIAs meeting CNBC’s proprietary criteria. CNBC
                        staff sent an email survey/application to all those
                        firms that met the initial criteria to gather more
                        details. In turn, those advisory firms filled out the
                        survey/application regarding their practice. The CNBC
                        team verified that data with those firms and with the
                        SEC regulatory database. AccuPoint once again applied
                        CNBC’s proprietary weighted categories to further refine
                        and rank the firms, ultimately creating the list of the
                        top 100 firms. Additionally, an advisor’s appearance on
                        the ranking does not constitute an individual
                        endorsement by CNBC of any firm. The primary data points
                        used in the analysis were reviewed, either as a minimum
                        baseline or within a range, eliminating those firms that
                        did not meet CNBC’s requirements. Once the initial list
                        was compiled, weightings were also applied accordingly.
                        These data points included: Advisory firm’s
                        regulatory/compliance record, number of years in the
                        business, number of certified financial planners, number
                        of employees, number of investment advisors registered
                        with the firm, the ratio of investment advisors to total
                        number of employees, total assets under management,
                        percentage of discretionary assets under management,
                        total accounts under management, number of states where
                        the RIA is registered and country of domicile. This
                        ranking was awarded by CNBC on 10/2/2024 in respect of
                        the calendar year period of 1/1/2024 to 12/31/2024. We
                        do not compensate CNBC to obtain this rating. However,
                        we pay compensation to CNBC to use their logo in
                        connection with advertising this ranking. Zacks
                        Investment Management did not provide any performance
                        metrics to CNBC in regard to this ranking.
                        <br />
                        The PSN Categories used for comparative analysis are
                        constructed by PSN (median performance) and data is
                        provided to Zacks by PSN. The peer groups were created
                        using the information collected through the PSN
                        investment manager questionnaire and uses only gross of
                        fee returns. PSN Top Guns investment managers must claim
                        that they are GIPs compliant. Products must have an
                        R-Squared of 0.80 or greater relative to the style
                        benchmark for the recent five year period. Moreover,
                        products must have returns greater than the style
                        benchmark for the three latest three-year rolling
                        periods. Products are then selected which have a
                        standard deviation for the five year period equal or
                        less than the median standard deviation for the peer
                        group. The top ten information ratios for the latest
                        five-year period then become the TOP GUNS. Other
                        managers included in the category by PSN may exhibit
                        style drift when compared to Zacks Investment Management
                        portfolio. Neither Zacks Investment Management nor Zacks
                        Investment Research has any affiliation with PSN.
                        Neither Zacks Investment Management nor Zacks Investment
                        Research had any influence of the process PSN used to
                        determine this rating. This rating was awarded by PSN on
                        8/19/2024 in respect of the period from 7/1/2019 to
                        6/30/2024 We do not compensate PSN to obtain this rating
                        However, we pay compensation to PSN to use their logo in
                        connection with advertising this rating.
                        <br />
                        The Morningstar Universes used for comparative analysis
                        are constructed by Morningstar (median performance) and
                        data is provided to Zacks by Zephyr Style Advisor. The
                        percentile ranking for each Zacks Strategy is based on
                        the gross comparison for Zacks Strategies vs. the
                        indicated universe rounded up to the nearest whole
                        percentile. Other managers included in universe by
                        Morningstar may exhibit style drift when compared to
                        Zacks Investment Management portfolio. Neither Zacks
                        Investment Management nor Zacks Investment Research has
                        any affiliation with Morningstar. Neither Zacks
                        Investment Management nor Zacks Investment Research had
                        any influence of the process Morningstar used to
                        determine this ranking. These ratings were awarded by
                        Morningstar on 10/1/2024 in respect of the period from
                        strategy inception to 9/30/2024 (Inception Dates: All
                        Cap- 2/1/1995, Focus Growth- 2/1/2003, Dividend-
                        4/1/2004, Mid and Small Cap- 5/1/2009). We do not
                        compensate Morningstar to obtain this rating. However,
                        we pay compensation to Morningstar to use their logo in
                        connection with advertising this rating.
                      </Typography>
                      <Typography
                        variant="caption"
                        onClick={toggleDisclaimer}
                        style={{
                          cursor: "pointer",
                          color: "#124734",
                          textDecoration: "underline",
                        }}
                      >
                        {isExpanded ? "read less" : "read more"}
                      </Typography>
                    </div>
                  </DialogContent>
                </>
              ) : (
                <>
                  <Typography variant="body1">
                    Please book a slot at your convenience, and we'll arrange a
                    meet with our expert.
                  </Typography>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "77px",
                    }}
                  >
                    <Box>
                      <Button
                        tabIndex={-1}
                        startIcon={<EditCalendarIcon />}
                        color="success"
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          padding: "10px 60px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://calendly.com/zacks-investment-management/30min?month=2024-12",
                            "_blank"
                          )
                        }
                      >
                        Schedule Your Free Call
                      </Button>
                    </Box>
                  </DialogActions>
                  <DialogContent>
                    <Typography variant="caption" color="text.secondary">
                      * By submitting, you authorize Zacks Insight and its
                      affiliates can call or text me for any purpose at any
                      number provided, including via automatic phone dialing
                      system and/or pre-recorded voice, and such consent is not
                      required to purchase goods or services as I may always
                      call directly at 800.767.3771 ext. 9339
                    </Typography>
                  </DialogContent>
                </>
              )}
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default HotHandOff;
