import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  StyledContainer,
  StyledHeading,
  StyledSubHeading,
  StyledSearchBar,
  StyledTextField,
  StyledSearchButton,
  StyledBox,
  StyledQuestions,
  StyledTypography,
} from "./HeroSectionStyled.jsx";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import { useTheme } from "@mui/material/styles";

export default function HeroSection({ handleLoginClick, handleRegisterClick }) {
  const [searchText, setSearchText] = useState("");
  const theme = useTheme();

  const handleCardClick = (item) => {
    setSearchText(item);
  };
  return (
    <StyledContainer>
      {/* Heading Section */}
      <StyledHeading>Meet Zacks Insight.</StyledHeading>
      <StyledSubHeading>
        Your AI-powered investment research assistant.
      </StyledSubHeading>

      {/* Button Section */}
      <CustomButton
        color="herogreen"
        variant="contained"
        onClick={handleRegisterClick}
        style={{ gap: "20px" }}
      >
        Get Started
      </CustomButton>

      {/* Search Bar */}
      <StyledSearchBar>
        <StyledTextField
          fullWidth
          variant="standard"
          placeholder="What can we help you research?"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: "3vw",
              color: "#333",
              [theme.breakpoints.up("md")]: {
                fontSize: "1vw", // 1% of viewport width for medium and larger screens
              },
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLoginClick();
            }
          }}
        />
        <StyledSearchButton>
          <SearchIcon fontSize="large" onClick={() => handleLoginClick()} />
        </StyledSearchButton>
      </StyledSearchBar>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // Full-width for small screens
            md: "repeat(2, 1fr)", // Two columns for medium and larger screens
          },
          gap: "0.2rem",
          justifyContent: "center",
          maxWidth: {
            xs: "100vw", // Full width for small screens
            md: "60vw", // 55% of viewport width for medium and larger screens
          },
          margin: "0.5vw auto", // Center the box horizontally
        }}
      >
        {[
          "What does your research show about buying Apple?",
          "What does your analysis show about selling Intel?",
        ].map((item, index) => (
          <StyledBox key={index} onClick={() => handleCardClick(item)}>
            <StyledQuestions>+</StyledQuestions>
            <StyledTypography>{item}</StyledTypography>
          </StyledBox>
        ))}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // Full-width for small screens
            md: "1fr", // Two columns for medium and larger screens
          },
          gap: "1rem",
          justifyContent: "center",
          maxWidth: {
            xs: "100vw", // Full width for small screens
            md: "60vw", // 55% of viewport width for medium and larger screens
          },
          margin: "0.5vw auto", // Center the box horizontally
        }}
      >
        {[
          "What are the top-ranked stocks with a history of positive EPS surprise?",
        ].map((item, index) => (
          <StyledBox key={index} onClick={() => handleCardClick(item)}>
            <StyledQuestions>+</StyledQuestions>
            <StyledTypography>{item}</StyledTypography>
          </StyledBox>
        ))}
      </Box>
    </StyledContainer>
  );
}
