import { styled } from "@mui/system"; // Import from @mui/system for better tree shaking
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// Styled Dialog components
export const StyledDialog = styled(Dialog)(({ theme }) => ({
  // maxWidth: "md",
  // fullWidth: true,
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  marginTop: "15px",
}));

export const StyledList = styled(List)(({ theme }) => ({
  paddingLeft: "20px",
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "0px",
  alignItems: "baseline",
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "30px",
}));

export const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)(
  ({ theme }) => ({
    fontSize: "10px",
  })
);

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "1vw 2vw",
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "2vw",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "10px 60px",
  borderRadius: 2,
  fontWeight: "400",
  // backgroundColor: "#369902",
  // color: "white",
  fontSize: "1vw",
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: "1vw",
  "& .MuiTypography-root": {
    fontSize: "1.2vw",
    [theme.breakpoints.up("md")]: {
      fontSize: "1vw !important", // 55% of viewport width for medium and larger screens
    },
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  fontSize: "1vw",
  padding: "1vw 2vw 0vw 2vw",
  "& .MuiTypography-root": {
    fontSize: "1.2vw",
    [theme.breakpoints.up("md")]: {
      fontSize: "1vw !important", // 55% of viewport width for medium and larger screens
    },
  },
}));
