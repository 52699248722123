import React, { useState, useEffect } from "react";
import { fetchData } from "../../services/apiConfig";
import CustomSuggestionProgress from "../atoms/Progress/CustomSuggestionProgress";
import {
  StyledPaperMain,
  StyledPaperHover,
  StyledTypography,
  StyledBox,
  StyledTitle,
  StyledImage,
} from "./Styled";
import { Grid } from "@mui/material";
import BuySell from "../../assests/BuySell.png";
import Reports from "../../assests/Reports.png";
import StockScreening from "../../assests/StockScreening.png";

const valueQuestions = [
  {
    prompt:
      "I’m looking for a short-term trade in the semiconductor sector. Any recent analyst reports on mid-cap chip manufacturers with strong quarterly guidance that I should be looking at?",
    icon: BuySell,
  },
  {
    prompt:
      "Can you suggest a large-cap conglomerate that balances stable income, low volatility, and a modest growth component for a retirement portfolio?",
    icon: Reports,
  },
  {
    prompt:
      "I want to adjust my retirement portfolio. Any insights on dividend-focused blue-chip stocks that are outperforming over the last 12 months that I should be looking at?",
    icon: StockScreening,
  },
];

const QuestionTile = ({ question, image, setNewMessage }) => (
  <>
    <StyledPaperMain onClick={() => setNewMessage(question)}>
      <StyledImage src={image} alt="icons" />
    </StyledPaperMain>
    <StyledPaperHover onClick={() => setNewMessage(question)}>
      <StyledTypography variant="h6">{question}</StyledTypography>
    </StyledPaperHover>
  </>
);

const SuggestionTile = ({ setNewMessage, isMobile }) => {
  const [suggestQuestions, setSuggestQuestions] = useState([]);

  useEffect(() => {
    try {
      const email = localStorage.getItem("email");
      if (email) {
        fetchData("getSuggest", "POST", { email: email }).then((response) => {
          setSuggestQuestions(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <StyledBox>
      <StyledTitle variant="h6">POPULAR QUESTIONS</StyledTitle>
      <Grid container spacing={2}>
        {suggestQuestions && suggestQuestions.length > 0 ? (
          valueQuestions.map((item, index) => (
            <Grid
              item
              xs={12} // Full width on small screens
              sm={4} // Full width on small screens
              md={4} // 50% width on medium screens
              lg={4} // 50% width on large screens
              key={index}
            >
              <QuestionTile
                question={item.prompt}
                image={item.icon}
                setNewMessage={setNewMessage}
              />
            </Grid>
          ))
        ) : (
          <CustomSuggestionProgress />
        )}
      </Grid>
    </StyledBox>
  );
};

export default SuggestionTile;
