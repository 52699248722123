import styled from "@mui/system/styled"; // Ensure you are importing styled correctly
import heroSectionImage from "../../../assests/heroSectionImage.svg";
import { Container, Typography, Box, TextField, Button } from "@mui/material";

export const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundImage: `url(${heroSectionImage})`, // Path relative to the public folder
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "80vh !important",
  padding: "0px !important",
  width: "100vw !important",
  maxWidth: "100vw !important",
  color: "#fff", // White text
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Inter, sans-serif",
  backgroundColor: "#011f17",
  [theme.breakpoints.up("md")]: {
    height: "90vh !important", // 55% of viewport width for medium and larger screens
  },
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "inherit",
  fontSize: "9vw !important", // Responsive font size
  fontWeight: "bold", // Optional: Customize font weight
  [theme.breakpoints.up("md")]: {
    fontSize: "5vw !important", // 55% of viewport width for medium and larger screens
  },
}));
export const StyledSubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "inherit",
  fontSize: "3.5vw !important", // Responsive font size
  color: "#369902",
  marginBottom: "2rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "2vw !important", // 55% of viewport width for medium and larger screens
  },
}));

export const StyledSearchBar = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "90%", // Default width
  marginBottom: "3rem",
  fontFamily: "inherit",
  maxWidth: "80vw", // Full width for small screens
  [theme.breakpoints.up("sm")]: {
    maxWidth: "55vw", // 55% of viewport width for medium and larger screens
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  padding: "1rem 2rem",
  fontFamily: "inherit",
}));

export const StyledSearchButton = styled(Button)(({ theme }) => ({
  fontFamily: "inherit",
  backgroundColor: "#369902",
  color: "#fff",
  minWidth: "50px",
  borderRadius: "0% 50% 50% 0%",
  padding: "1rem",
  "&:hover": { backgroundColor: "#36b02d" },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#124734", // Dark green card background
  borderRadius: "2rem",
  padding: "0.25vw 1vw",
  maxWidth: "80vw",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: "pointer",
  fontFamily: "inherit",
  "&:hover": { backgroundColor: "#36b02d" },
}));

export const StyledQuestions = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontWeight: "100",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "5vw",
  fontFamily: "inherit",
  [theme.breakpoints.up("md")]: {
    fontSize: "2vw", // 55% of viewport width for medium and larger screens
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "3vw",
  fontFamily: "inherit",
  textAlign: "left",
  [theme.breakpoints.up("md")]: {
    fontSize: "1vw", // 55% of viewport width for medium and larger screens
  },
}));
