import React, { useState } from "react";
import {
  StyledParagraph,
  FullDisclaimer,
  ReadMoreButton,
  StyledParagraph1,
} from "./Styled";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ChatFooter = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDisclaimer = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <Container
        style={{
          height: isExpanded ? "130px" : "30px",
          marginTop: isExpanded ? "-100px" : "0px",
          backgroundColor: "#e0e0e0",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "flex-end",
          position: "relative",
          maxWidth: "100vw",
          padding: "0px",
        }}
      >
        {isExpanded ? (
          <FullDisclaimer>
            <strong>Disclaimer: </strong> This material is being provided for
            informational purposes only and nothing herein constitutes tailored
            investment, legal, accounting or tax advice, or an individualized
            recommendation to buy, sell or hold a security. Do not act or rely
            upon the information and advice given in this publication without
            seeking the services of competent and professional legal, tax, or
            accounting counsel. Distribution of this article is not intended to
            create, and the information contained herein does not constitute, an
            attorney-client relationship. No recommendation or advice is being
            given as to whether any investment or strategy is suitable for a
            particular investor. It should not be assumed that any investments
            in securities, companies, sectors or markets identified and
            described were or will be profitable. All information is current as
            of the date of herein and is subject to change without notice. Any
            views or opinions expressed may not reflect those of the firm.
          </FullDisclaimer>
        ) : (
          <StyledParagraph>
            <strong>Disclaimer: </strong> This material is being provided for
            informational purposes only and nothing herein constitutes tailored
            investment, legal, accounting or tax advice, or an individualized
            recommendation to buy, sell or hold a security. Do not act or rely
            upon the information and advice given in this publication without
            seeking the services of competent and professional legal, tax, or
            accounting counsel. Distribution of this article is not intended to
            create, and the information contained herein does not constitute, an
            attorney-client relationship. No recommendation or advice is being
            given as to whether any investment or strategy is suitable for a
            particular investor. It should not be assumed that any investments
            in securities, companies, sectors or markets identified and
            described were or will be profitable. All information is current as
            of the date of herein and is subject to change without notice. Any
            views or opinions expressed may not reflect those of the firm.
          </StyledParagraph>
        )}
        <ReadMoreButton onClick={toggleDisclaimer}>
          {isExpanded ? (
            <CloseIcon
              sx={{
                fontSize: "15px",
                color: "grey",
                border: "1px solid grey",
                borderRadius: "50%",
              }}
            />
          ) : (
            "read more"
          )}
        </ReadMoreButton>
      </Container>

      <Container
        style={{
          height: "36px",
          backgroundColor: "#e0e0e0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          maxWidth: "100vw",
          padding: "0px",
        }}
      >
        <StyledParagraph1>
          {/* {!isMobile &&
            "Zack Intelligence information is provided 'as is' and solely for informational purposes and is delayed. "} */}

          <Link
            target="_blank"
            to="/termsandconditions"
            style={{
              textDecoration: "underline",
              color: "#369902",
              marginLeft: "10px",
            }}
          >
            Terms & Conditions
          </Link>
          <Link
            target="_blank"
            to="/disclosures"
            style={{
              textDecoration: "underline",
              color: "#369902",
              marginLeft: "10px",
            }}
          >
            Disclosures
          </Link>
          <Link
            target="_blank"
            to="/privacypolicy"
            style={{
              textDecoration: "underline",
              color: "#369902",
              marginLeft: "10px",
            }}
          >
            Privacy Policy
          </Link>
          <Link
            onClick={() =>
              (window.location.href =
                "mailto:support@zacksintelligence.com?subject=Support")
            }
            style={{
              textDecoration: "underline",
              color: "#124734",
              marginLeft: "10px",
            }}
          >
            Customer Support - 312-265-9157
          </Link>
        </StyledParagraph1>
      </Container>
    </div>
  );
};

export default ChatFooter;
