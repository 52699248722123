import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import zackNewLogo from "../../assests/zackNewLogo.png";
import { Card, CardContent, Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const pdfFiles = [
  {
    name: "Privacy Notice",
    url: "https://zipublicdocs.blob.core.windows.net/public-docs/PrivacyNotice-ZacksIntelligence.pdf",
  },
];

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      {/* Logo Section */}
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mt: 4 }}
        style={{ cursor: "pointer" }}
        onClick={() => window.location.replace("/")}
      >
        <Grid item>
          <Box
            component="img"
            src={zackNewLogo} // Replace with your logo path
            alt="Logo"
            sx={{ width: 50, height: 38 }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="div">
            ZACKS
          </Typography>
        </Grid>
      </Grid>

      {/* Terms and Conditions Content */}
      <Box mt={4} mb={4}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          style={{ display: "flex", justifyContent: "center" }}
        >
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Below is an overview of how Zacks Intelligence LLC collects, shares,
          and protects your personal information, including the reasons
          financial companies can share, why we share, and whether you can limit
          this sharing. Please review our table for details, and download our
          Privacy Policy (PDF) for the full notice.
        </Typography>
        {/* <Typography variant="h6" component="h2" gutterBottom>
          1. ADV Form */}
        <Grid container spacing={2} style={{ padding: "2vw 0px" }}>
          {pdfFiles.map((file, index) => (
            <>
              {/* <Typography variant="h6" component="h2" gutterBottom>
                {index + 1}. {file.name}
              </Typography> */}
              <Grid item xs={12} sm={12} md={12} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "16px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      fontSize: "3rem",
                      color: "#e91e63",
                      marginRight: "16px",
                    }}
                  />
                  <CardContent sx={{ flex: 1 }}>
                    <Typography
                      variant="body1"
                      noWrap
                      title={file.name}
                      sx={{ fontWeight: "bold" }}
                    >
                      {file.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      PDF
                    </Typography>
                  </CardContent>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    href={file.url}
                    download
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Security best practice
                  >
                    Download
                  </Button>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>
        {/* </Typography> */}
        <Typography variant="body1" paragraph>
          Zacks Intelligence - Customer Support - <strong> 312-265-9157</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, feel free to contact us.
        </Typography>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          mt: "auto",
          width: "100%",
          textAlign: "center",
          borderTop: "1px solid #ccc",
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} Zacks intelligence. All rights reserved.
        </Typography>
        <Typography variant="body2">
          Zacks Intelligence LLC 10 S. Riverside Plaza, Suite 1600 Chicago, IL
          60606 (312)265-9157
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
